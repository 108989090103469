import {LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT, SET_AUTH_INITIAL_STATE} from "../../types";

export default (state, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            sessionStorage.setItem('company', JSON.stringify(action.payload.company));
            return {
                ...state,
                authState: action.payload.authState,
                user: action.payload.user,
                company: action.payload.company,
                alert: action.payload.alert
            }
            break;
        case LOGOUT:
            sessionStorage.removeItem('company');
            return {
                ...state,
                authState: null,
                user: null,
                alert: null
            }
            break;
        case LOGIN_ERROR:
            sessionStorage.removeItem('company');
            return {
                ...state,
                authState: null,
                user: null,
                alert: action.payload
            }
            break;
        case SET_AUTH_INITIAL_STATE:
            return {
                ...state,
                authState: action.payload.authState,
                user: action.payload.user
            }
            break;
        default:
            return state;
    }
}
