import React, {useContext, useEffect} from 'react';
import {AuthState} from "@aws-amplify/ui-components";
import AuthContext from "../../context/auth/authContext";
import SuperAdminHome from "../HomeSuperAdmin/SuperAdminHome";
import AdminHome from "../HomeAdmin/AdminHome";
import Home from "../Home/Home";
import Login from './Login';
//import {AmplifyAuthenticator, AmplifySignIn, AmplifySignUp} from "@aws-amplify/ui-react";
import {Auth} from 'aws-amplify';
import '../../App.css';


const Auth1 = ( ) => {
    const authContext = useContext(AuthContext);
    const {authState, user, setAuthInitialState, logout, company} = authContext;

    useEffect(() => {
        if (!authState) {
            checkUser();
        }
    }, [authState]);


    async function checkUser() {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            if (!currentUser) {
               logout();
            } else {
                setAuthInitialState({
                    authState: AuthState.SignedIn,
                    user:currentUser
                });
            }
        }catch (err) {
            logout();
        }
    }

    
    return (
        <React.Fragment>
            {

                authState === AuthState.SignedIn && user && user.attributes ? (
                        user.attributes['custom:role'] === 'SuperAdmin' ?
                            <SuperAdminHome
                                email = {user.attributes.email}
                                role = {user.attributes['custom:role']}
                                tenant = {user.attributes['custom:tenant']}
                            />
                            : (
                                user.attributes['custom:role'] === 'Admin' ?
                                    <AdminHome/>
                                    :
                                    <Home
                                        email = {user.attributes.email}
                                        role = {user.attributes['custom:role']}
                                        tenant = {user.attributes['custom:tenant']}
                                    />
                            )
                            ) :
                    <div className="App">
                        {/*<div  className="p-grid p-fluid logo-init">
                            <div className="p-col-12 p-md-12">
                            </div>
                        </div>
                        <AmplifyAuthenticator usernameAlias="email">
                            <AmplifySignIn
                                headerText="WELCOME"
                                slot="sign-in"
                                usernameAlias="email"
                                hideSignUp="false"
                                submitButtonText="Log In"
                            >
                            </AmplifySignIn>

                            <AmplifySignUp
                                slot="sign-up"
                                usernameAlias="email"
                                formFields={[
                                    {
                                        type: "email",
                                        label: "Email Address *",
                                        placeholder: "Email",
                                        required: true
                                    },
                                    {
                                        type: "password",
                                        label: "Password *",
                                        placeholder: "Password",
                                        required: true
                                    },
                                    {
                                        type: "custom:role",
                                        label: "Role *",
                                        placeholder: "Role",
                                        required: false
                                    },
                                    {
                                        type: "custom:tenant",
                                        label: "Tenant *",
                                        placeholder: "Tenant",
                                        required: false
                                    },
                                    {
                                        type: "custom:asanaGid",
                                        label: "Asana Id *",
                                        placeholder: "Asana Id",
                                        required: false
                                    }
                                ]}
                            />
                            
                            </AmplifyAuthenticator>*/}
                        <Login />
                    </div>
            }
        </React.Fragment>
    );
}

export default Auth1;
