import axiosAsanaClient from "./axios";

const asanaTokenAuth = token => {
    if(token) {
        axiosAsanaClient.defaults.headers.common['Authorization'] = 'Bearer ' + token
    } else {
        delete axiosAsanaClient.defaults.headers.common['Authorization'];
    }
}

export default asanaTokenAuth;
