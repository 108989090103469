import React from 'react';
import {AmplifyGreetings} from "@aws-amplify/ui-react";

const Home = ( {  email, role, tenant} ) => {
    return (
        <div className="App">
            <AmplifyGreetings username={email}></AmplifyGreetings>
            <header>
                <h1>Its Home Page and you are a {role} and belongs to  {tenant}</h1>
            </header>
        </div>
    ) ;
}

export default Home;
