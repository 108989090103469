import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import gif from '../../static/rq_logo_animation_d5.gif'
import Login from './Login'
import Sended from './Sended'
import AuthContext from "../../context/auth/authContext";
import '../../App.css';

export default function ForgotPassword () {

    const [back, setBack] = useState(false);
    const [sended, setSended] = useState(false);
    const [userExist, setUserExist] = useState(false);

    const authContext = useContext(AuthContext);
    const {user} = authContext;
    
    const [data, setData] = useState({
        email: '',
        password: ''
    })
    

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name] : event.target.value
        })
    }

    let handleSubmit = async function (e) {
        e.preventDefault();
            let authData = await Auth.forgotPassword(data.email)
            .then(data => {
                setSended(true);
            })
            .catch(err => {
                console.log(err)
                setUserExist(true);
                setTimeout(function () {
                    setUserExist(false)
                }, 2500)
            }); 

            return authData

    }

    const handleBack = (e) => {
        setBack(true);
    }

    const handleSended = e => {
        setSended(true);
    }

    if(back) {
        return <Login />
    }else if (sended){
         return <Sended />
    }
    return (
        <form className="loginForm" onSubmit={handleSubmit}>
            <div className="container">
                <div className="p-col-12 p-md-12">
                    <img src={gif} alt="RED QUEEN" className="redQueen"/> 
                </div>
                <div className="card">
                    <h4 className="text1">RESET YOUR PASSWORD</h4>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-12">
                            <div className="p-inputgroup form">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-envelope"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText id="inputgroup" type="text" value={data.email} name="email" onChange={handleChange}/>
                                    <label htmlFor="inputgroup">User Name</label>
                                </span>
                            </div>
                            { 
                                userExist ? (
                                    <span className='error'>Invalid Username</span>
                                ):(
                                    <div></div>
                                )
                            }
                        </div>
                    </div>
                    <Button className="login-button" label="Send Code" type="submit"/>
                    <Button className="login-button" label="Back" onClick={handleBack}/>
                </div>
            </div>
        </form>
    )
}
