import React, {useContext, useEffect, useState} from 'react';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import { ProgressSpinner } from 'primereact/progressspinner';
import {StatusEnum} from "../../models";
import moment from "moment";
import {Button} from "primereact/button";
import { Divider } from 'primereact/divider';
import gif from '../../static/rq_logo_animation_d5.gif';
import warning from '../../assets/warning.png'
import {Calendar} from "primereact/calendar";
import {API, graphqlOperation} from 'aws-amplify';
import {createProjectAndTasks} from "../../graphql/mutations";
import ClientRequestContext from "../../context/clientRequest/clientRequestContext";
import AuthContext from "../../context/auth/authContext";
import './ClientRequest.css';
import {taskTemplateByCampaign} from "../../graphql/queries";
import {ClientRequest} from "../../models";

const ClientRequestConfirmation = () => {

    const clientRequestContext = useContext(ClientRequestContext);
    const {clientRequest, setActiveIndex, setPermalinkUrl, setClientRequest} = clientRequestContext;

    const [taskTemplates, setTaskTemplates] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [wrongLoading, setWrongLoading] = useState(false);

    const authContext = useContext(AuthContext);
    const {user, logout} = authContext;
    const email = user.attributes.email;
    const tenantId = user.attributes['custom:tenant'];

    useEffect(() => {
        getTaskTemplates();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    async function signOut() {
        logout();
    }

    const getTaskTemplates =  async () => {
        try {

            /*const taskTemplates = await  DataStore.query(TaskTemplate,
                c => c.campaignID("eq", clientRequest.campaign.id),{
                    sort: s => s.order(SortDirection.ASCENDING)
                });*/

            const taskTemplateByCampaignResult = await API.graphql(graphqlOperation(
                taskTemplateByCampaign, { campaignID: clientRequest.campaign.id})
            );


            const taskTemplates = taskTemplateByCampaignResult.data.taskTemplateByCampaign.items;

            const servicesIds= clientRequest.services.map( item=> item.id);
            const taskTemplatesFiltered = taskTemplates.filter( item => servicesIds.includes(item.serviceID));
            const taskTemplatesWithDate = taskTemplatesFiltered.map( item=> (
                    {
                        name: item.name,
                        description: item.description,
                        section: item.section,
                        dueDate: moment(clientRequest.dueDate).subtract(item.dueDateCalculation, 'days').format('MM/DD/YYYY'),
                        order: item.order,
                        serviceID: item.serviceID,
                        tenantId: item.tenantId
                    }
                )
            );
            setTaskTemplates(taskTemplatesWithDate);
            console.log("TaskTemplates retrieved successfully!"+ JSON.stringify(taskTemplatesFiltered));
        } catch (error) {
            console.log("Error retrieving TaskTemplates", error);
        }
    }

    const TaskBodyTemplate = (rowData) => {
        return <span>{rowData.name.replace('XN', clientRequest.campaignName)} </span>;
    }

    const onRowEditInit = (event) => {
        originalRows[event.index] = { ...taskTemplates[event.index] };
        setOriginalRows(originalRows);
    }

    const onRowEditCancel = (event) => {
        let canceledTaskTemplates = [...taskTemplates];
        canceledTaskTemplates[event.index] = originalRows[event.index];
        delete originalRows[event.index];
        setTaskTemplates(canceledTaskTemplates);
        setOriginalRows(originalRows);
    }

    const onEditorValueChange = (props, value) => {
        let updatedTaskTemplates = [...props.value];
        updatedTaskTemplates[props.rowIndex][props.field] = moment(value).format('MM/DD/YYYY');
        setTaskTemplates(updatedTaskTemplates);
    }

    const calendarTextEditor = (props, field) => {
        return <Calendar value={new Date(props.rowData[field])} onChange={(e) => onEditorValueChange(props, e.value)} appendTo={document.body}/>
    }

    const dueDateEditor = (props) => {
        return calendarTextEditor(props, 'dueDate');
    }

    const columns = [
        {field: 'name', header: 'TASK', template: TaskBodyTemplate},
        {field: 'description', header: 'TASK DESCRIPTION' },
        {field: 'section', header: 'SECTION'}
    ];

    const paginatorLeft = <Button type="button"  className="p-button-text"  onClick={getTaskTemplates}/>;

    const dynamicColumns = columns.map((col,i) => {
        return <Column key={col.field} field={col.field} header={col.header} body={col.template} sortable/>;
    });

    const back = async () => {
        console.log('click')
        setActiveIndex(0);
        setClientRequest(new ClientRequest({campaignName: ''}),);
    }

    const onExport =  async () => {
        setLoading(true);

         const clientRequestInput= {
                campaignID: clientRequest.campaign.id,
                services: clientRequest.services.map(s => {
                    return {
                        id: s.id,
                        name: s.name
                    }
                }),
                clientName: clientRequest.client.name,
                clientAsanaId: clientRequest.client.gid,
                campaignName: clientRequest.campaignName,
                //campaignAsanaId: "1212324" ,
                dueDate: new Date (clientRequest.dueDate).toISOString(),
                status: StatusEnum.PLANNED,
                progress: "0",
                owner: email,
                tenantId: tenantId
        };


        const tasksInput = taskTemplates.map( task => {
            task.dueDate = new Date (task.dueDate).toISOString();
            return task;
        } );

        const createProjectAndTasksInput  = {
            clientRequest: clientRequestInput,
            tasks: tasksInput
        }


        try {
            const result = await API.graphql(graphqlOperation(createProjectAndTasks, {input: createProjectAndTasksInput}));

            console.log("ClientRequest ans TaskTemplates saved successfully!", result);

            setPermalinkUrl(result.data.createProjectAndTasks);
            setActiveIndex(2);
        } catch (error) {
            console.log("Error saved ClientRequest ans TaskTemplates", error);
            setLoading(false);
            setWrongLoading(true);
            setTimeout(function () {
                setActiveIndex(0);
                setClientRequest(new ClientRequest({campaignName: ''}),);
            }, 2000)
        }
    }
    if(loading){
        return(
            <div className="container">
                <div className="p-grid p-fluid container2">
                    <div  className="p-inline p-fluid logo">
                        <div className="p-col-6 p-md-6">
                            <img src={gif} alt="RED QUEEN" className="redQueen"/> 
                        </div>
                    </div>
                    <div className="confirmation-content">
                        <ProgressSpinner style={{width: '50px', height: '50px', marginTop: '50px'}} strokeWidth="4" fill="#EEEEEE" animationDuration="3s"/>
                        <h4 className="text">EXPORTING CAMPAIGN...</h4>
                    </div>
                </div>
            </div>
        )
    }else if (wrongLoading){
        return(
            <div className="container">
                <div className="p-grid p-fluid container">
                    <div  className="p-inline p-fluid logo">
                        <div className="p-col-6 p-md-6">
                            <img src={gif} alt="RED QUEEN" className="redQueen"/> 
                        </div>
                    </div>
                    <div className="confirmation-content">
                        <img src={warning} alt="warning" className="warning"/>
                        <h4 className="text">SOMETHING WENT WRONG, PLEASE TRY AGAIN.</h4>
                    </div>
                </div>
            </div>
        )
    }else{
    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                    <div className="p-grid p-fluid container1">
                        <div  className="p-inline p-fluid logo">
                            <div className="p-col-6 p-md-6">
                                <img src={gif} alt="RED QUEEN" className="redQueen"/> 
                            </div>
                            <div className="logout-button-box">
                                <Button className="logout-button" label="Log Out" onClick={signOut} />
                            </div>
                        </div>
                    </div>
          
                <Divider align="left" className="divider">
                    <b>04  <i className="pi pi-minus"></i>  CAMPAIGN CONFIRMATION</b>
                </Divider>
                <DataTable
                           dataKey="id"
                           className="p-datatable-responsive-demo"
                           value={taskTemplates}
                           header={clientRequest.campaignName}
                           editMode="row"
                           onRowEditInit={onRowEditInit}
                           onRowEditCancel={onRowEditCancel}
                           scrollable scrollHeight="600px"
                           sortMode="multiple" removableSort
                           paginator
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                           rows={5} rowsPerPageOptions={[5,10,20,50]}
                           paginatorLeft={paginatorLeft}>
                    {dynamicColumns}
                    <Column key="dueDate" field="dueDate" header="DUE DATE"  editor={(props) => dueDateEditor( props)} sortable></Column>
                    <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
                <div className="button-box-exp" style={{paddingTop: '2em'}}>
                    <Button label="Back"  className="p-button-rounded login-button" onClick={back}/>
                    <Button label="Export"  className="p-button-rounded login-button" onClick={onExport}/>
                </div>
            </div>
        </div>
    );
    }
}

export default ClientRequestConfirmation;

