import React, {useState, useContext, useEffect, useRef} from 'react';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputText } from "primereact/inputtext";
import {Password} from 'primereact/password';
import { Button } from 'primereact/button';
import gif from '../../static/rq_logo_animation_d5.gif'
import ForgotPassword from './ForgotPassword'
import AuthContext from "../../context/auth/authContext";
import '../../App.css';
import {Messages} from "primereact/messages";

export default function Login () {

    const authContext = useContext(AuthContext);
    const { login , alert} = authContext;
    const [forgot, setForgot] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);

    const alertMessage = useRef(null);
    
    const [data, setData] = useState({
        email: '',
        password: ''
    })


    useEffect( ()=> {
        alertMessage.current.clear();
        if(alert) {
            switch (alert.type) {
                case 'success':
                    alertMessage.current.show( { severity: 'success', summary: 'Login', detail: alert.message, sticky: false, life: 1000 });
                    break;
                case 'error':
                    alertMessage.current.show( { severity: 'error', summary: 'Login', detail: alert.message, sticky: false, life: 1000 });
                    break;
                default:
                    alertMessage.current.show( { severity: 'info', summary: 'Login', detail: alert.message, sticky: false, life: 1000 });
                    break;
            }
        }

    }, [alert]);

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name] : event.target.value
        })
    }

    let handleSubmit = async function (e) {
        e.preventDefault();
        login(data);
    }

    const handleForgetPassword = (e) => {
        setForgot(true);
    }

    let togglePasswordVisibility = () => {
        setPasswordShow(!passwordShow );
    }

    if(forgot) {
        return <ForgotPassword />
    }

    return (
         <form className="loginForm" onSubmit={handleSubmit}>
            <Messages ref={alertMessage} />
            <div className="container">
                <div className="p-col-12 p-md-12">
                    <img src={gif} alt="RED QUEEN" className="redQueen"/> 
                </div>
                <div className="card">
                    <h4 className="text1">WELCOME</h4>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-12">
                            <div className="p-inputgroup form">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-envelope"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText id="inputgroup" type="text" value={data.email} name="email" onChange={handleChange}/>
                                    <label htmlFor="inputgroup">User Name</label>
                                </span>
                            </div>
                        </div>
    
                        <div className="p-col-12 p-md-12">
                            <div className="p-inputgroup form">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-lock"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText id="inputgroup1" type={passwordShow ? "text" : "password"}  value={data.password} name="password" onChange={handleChange} toggleMask/>
                                    <label htmlFor="inputgroup">Password</label>
                                </span>
                                <span className="p-inputgroup-addon eye">
                                    <i className={passwordShow ? "pi pi-eye" : "pi pi-eye-slash"} onClick={togglePasswordVisibility}></i>
                                </span>
                            </div>
                            <div className="p-inputgroup forgot-box">
                                <a href="#"className="forgot" onClick={handleForgetPassword}>Forgot your password?</a>
                            </div>
                        </div>
                    </div>
                    <Button className="login-button" label="Log In" type="submit"/>
                </div>
            </div>
        </form>
    )
}
