/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProjectAndTasks = /* GraphQL */ `
  mutation CreateProjectAndTasks($input: CreateProjectAndTasksInput!) {
    createProjectAndTasks(input: $input)
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      description
      address
      phone
      subscriptionState
      subscriptionPlan
      destinationPersonalToken
      destinationWorkspace
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      description
      address
      phone
      subscriptionState
      subscriptionPlan
      destinationPersonalToken
      destinationWorkspace
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      description
      address
      phone
      subscriptionState
      subscriptionPlan
      destinationPersonalToken
      destinationWorkspace
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      name
      description
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      services {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      name
      description
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      services {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      name
      description
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      services {
        nextToken
        startedAt
      }
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      campaignID
      name
      description
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      campaignID
      name
      description
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      campaignID
      name
      description
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTaskTemplate = /* GraphQL */ `
  mutation CreateTaskTemplate(
    $input: CreateTaskTemplateInput!
    $condition: ModelTaskTemplateConditionInput
  ) {
    createTaskTemplate(input: $input, condition: $condition) {
      id
      campaignID
      serviceID
      name
      description
      dueDateCalculation
      section
      order
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTaskTemplate = /* GraphQL */ `
  mutation UpdateTaskTemplate(
    $input: UpdateTaskTemplateInput!
    $condition: ModelTaskTemplateConditionInput
  ) {
    updateTaskTemplate(input: $input, condition: $condition) {
      id
      campaignID
      serviceID
      name
      description
      dueDateCalculation
      section
      order
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTaskTemplate = /* GraphQL */ `
  mutation DeleteTaskTemplate(
    $input: DeleteTaskTemplateInput!
    $condition: ModelTaskTemplateConditionInput
  ) {
    deleteTaskTemplate(input: $input, condition: $condition) {
      id
      campaignID
      serviceID
      name
      description
      dueDateCalculation
      section
      order
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createClientRequest = /* GraphQL */ `
  mutation CreateClientRequest(
    $input: CreateClientRequestInput!
    $condition: ModelClientRequestConditionInput
  ) {
    createClientRequest(input: $input, condition: $condition) {
      id
      campaignID
      services
      clientName
      clientAsanaId
      campaignName
      campaignAsanaId
      dueDate
      status
      progress
      tenantId
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientRequest = /* GraphQL */ `
  mutation UpdateClientRequest(
    $input: UpdateClientRequestInput!
    $condition: ModelClientRequestConditionInput
  ) {
    updateClientRequest(input: $input, condition: $condition) {
      id
      campaignID
      services
      clientName
      clientAsanaId
      campaignName
      campaignAsanaId
      dueDate
      status
      progress
      tenantId
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientRequest = /* GraphQL */ `
  mutation DeleteClientRequest(
    $input: DeleteClientRequestInput!
    $condition: ModelClientRequestConditionInput
  ) {
    deleteClientRequest(input: $input, condition: $condition) {
      id
      campaignID
      services
      clientName
      clientAsanaId
      campaignName
      campaignAsanaId
      dueDate
      status
      progress
      tenantId
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      team
      name
      workspace
      asanaId
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      clientRequest {
        id
        campaignID
        services
        clientName
        clientAsanaId
        campaignName
        campaignAsanaId
        dueDate
        status
        progress
        tenantId
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      owner
      tasks {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      team
      name
      workspace
      asanaId
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      clientRequest {
        id
        campaignID
        services
        clientName
        clientAsanaId
        campaignName
        campaignAsanaId
        dueDate
        status
        progress
        tenantId
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      owner
      tasks {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      team
      name
      workspace
      asanaId
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      clientRequest {
        id
        campaignID
        services
        clientName
        clientAsanaId
        campaignName
        campaignAsanaId
        dueDate
        status
        progress
        tenantId
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      owner
      tasks {
        nextToken
        startedAt
      }
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      name
      notes
      dueDate
      section
      workspace
      projectID
      asanaId
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      name
      notes
      dueDate
      section
      workspace
      projectID
      asanaId
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      name
      notes
      dueDate
      section
      workspace
      projectID
      asanaId
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
