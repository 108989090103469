import React, {useReducer} from "react";
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import {LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT, SET_AUTH_INITIAL_STATE} from "../../types";
import {API, Auth, graphqlOperation} from "aws-amplify";
import {getCompany} from "../../graphql/queries";
import asanaTokenAuth from "../../config/asanaToken";


const AuthState = props => {
    const initialState = {
        authState: null,
        user: null,
        company: JSON.parse(sessionStorage.getItem('company')),
        alert: null
    }

    const [state, dispatch]  = useReducer(AuthReducer, initialState);

    const setAuthInitialState = initialState => {
        dispatch ({
            type: SET_AUTH_INITIAL_STATE,
            payload: initialState
        });
    }

    const login = async data => {

        try {
            let user = await Auth.signIn(data.email, data.password);
            let company;

            if(user) {
                company = await getCurrentCompany(user);

                asanaTokenAuth(company.destinationPersonalToken);

                const alert = {
                    message: 'Successful login',
                    type: 'success'
                }

                dispatch ({
                    type: LOGIN_SUCCESS,
                    payload: {
                        authState: 'signedin',
                        user: user,
                        company: company,
                        alert: alert
                    }
                });
            } else {
                const alert = {
                    message: 'Invalid Credentials',
                    type: 'error'
                }

                dispatch ({
                    type: LOGIN_ERROR,
                    payload: alert
                });
            }

        } catch (error) {
            console.log(error);

            const alert = {
                message: ': Invalid Credentials',
                type: 'error'
            }

            dispatch ({
                type: LOGIN_ERROR,
                payload: alert
            });
        }
    }

    const logout = async () => {

        try {
            await Auth.signOut({ global: true });

            const alert = {
                message: 'Successful logout',
                type: 'success'
            }

            dispatch ({
                type: LOGOUT,
                payload: alert
            });
        } catch (error) {
            console.log(error);
            dispatch ({
                type: LOGOUT
            });
        }
    }


    const getCurrentCompany = async (user) => {
        const tenantId = user.attributes['custom:tenant'];
        try {
            const getCompanyResult = await API.graphql(graphqlOperation(getCompany, { id: tenantId }));
            const company = getCompanyResult.data.getCompany;
            console.log("Company retrieved successfully!", JSON.stringify(company));
            return company;
        } catch (error) {
            console.log("Error retrieving the company", error);
        }
        return null;
    }

    return (
        <AuthContext.Provider
            value={{
                authState: state.authState,
                user: state.user,
                company: state.company,
                alert: state.alert,
                setAuthInitialState,
                login,
                logout
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState;
