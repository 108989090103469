import React, {useState} from 'react';
import styled from 'styled-components';
import Campaign from "../Campaign/Campaign";
import ClientRequestWizard from "../ClientRequest/ClientRequestWizard";
import "../ClientRequest/ClientRequest.css"
import ClientRequestState from "../../context/clientRequest/clientRequestState";



const Main =styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    
`;

const AdminHome = ( ) => {

    const [selected, setSelected] = useState('client-request');

    const onSelect = (selected) => {
        setSelected(selected);
    }

    const renderComponent = () => {
        return (
            <div>
                {
                    selected === 'client-request' ? (
                        // Its for phase 2 <ClientRequestList/>
                        <ClientRequestState>
                            <ClientRequestWizard/>
                        </ClientRequestState>
                    ) :
                        <Campaign/>
                }
            </div>
        );
    }

    return (
        <div className="App">
            {/*<AmplifyGreetings username={email}>

                <div  slot="nav" style={{ color: '#314E73'}}>
                    <amplify-nav className="hydrated">
                        <div style={{ color: '#314E73', paddingRight: '1em'}}>
                            <img src={logo} className="App-logo" alt="logo" />
                        </div>
                        <div style={{ color: '#314E73', paddingRight: '40em'}}>
                            <h1>Red Queen</h1>
                        </div>
                        <div style={{ color: '#314E73', paddingRight: '1em'}}>
                            <slot name="greetings-message"><span>Hello, {email}</span></slot>
                        </div>
                        <div style={{ color: '#314E73', paddingRight: '1em'}}>
                            <amplify-sign-out className="hydrated button-out"></amplify-sign-out>
                        </div>

                    </amplify-nav>
                </div>

    </AmplifyGreetings>*/}
            <div>
                {/*<SideNav onSelect={ onSelect}>
                    <SideNav.Toggle />
                    <SideNav.Nav selected={ selected}>
                        <NavItem eventKey ='client-request'>
                            <NavIcon>
                                <i className="pi pi-user" style={{ fontSize: "1.75em" }} />
                            </NavIcon>
                            <NavText style={{paddingRight: 32}} title="Client Request">
                                Client Request
                            </NavText>
                        </NavItem>

                        <NavItem eventKey ='campaign'>
                            <NavIcon>
                                <i className="pi pi-palette" style={{ fontSize: "1.75em" }} />
                            </NavIcon>
                            <NavText style={{paddingRight: 32}} title="Campaign">
                                Campaign
                            </NavText>
                        </NavItem>
                    </SideNav.Nav>
    </SideNav>*/}
                <Main>
                    {renderComponent()}
                </Main>
            </div>


            { /*<footer>
                <h1>Its Admin Page and you are a {role} and belongs to  {tenant}</h1>
            </footer> */}
        </div>
    ) ;
}

export default AdminHome;
