/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      description
      address
      phone
      subscriptionState
      subscriptionPlan
      destinationPersonalToken
      destinationWorkspace
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        address
        phone
        subscriptionState
        subscriptionPlan
        destinationPersonalToken
        destinationWorkspace
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCompanies = /* GraphQL */ `
  query SyncCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        address
        phone
        subscriptionState
        subscriptionPlan
        destinationPersonalToken
        destinationWorkspace
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      name
      description
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      services {
        nextToken
        startedAt
      }
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const campaignByTenant = /* GraphQL */ `
  query CampaignByTenant(
    $tenantId: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignByTenant(
      tenantId: $tenantId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCampaigns = /* GraphQL */ `
  query SyncCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCampaigns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      campaignID
      name
      description
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaignID
        name
        description
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncServices = /* GraphQL */ `
  query SyncServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaignID
        name
        description
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getTaskTemplate = /* GraphQL */ `
  query GetTaskTemplate($id: ID!) {
    getTaskTemplate(id: $id) {
      id
      campaignID
      serviceID
      name
      description
      dueDateCalculation
      section
      order
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTaskTemplates = /* GraphQL */ `
  query ListTaskTemplates(
    $filter: ModelTaskTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaignID
        serviceID
        name
        description
        dueDateCalculation
        section
        order
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const taskTemplateByCampaign = /* GraphQL */ `
  query TaskTemplateByCampaign(
    $campaignID: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    taskTemplateByCampaign(
      campaignID: $campaignID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaignID
        serviceID
        name
        description
        dueDateCalculation
        section
        order
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTaskTemplates = /* GraphQL */ `
  query SyncTaskTemplates(
    $filter: ModelTaskTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTaskTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaignID
        serviceID
        name
        description
        dueDateCalculation
        section
        order
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const listClientRequests = /* GraphQL */ `
  query ListClientRequests(
    $filter: ModelClientRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaignID
        services
        clientName
        clientAsanaId
        campaignName
        campaignAsanaId
        dueDate
        status
        progress
        tenantId
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientRequest = /* GraphQL */ `
  query GetClientRequest($id: ID!) {
    getClientRequest(id: $id) {
      id
      campaignID
      services
      clientName
      clientAsanaId
      campaignName
      campaignAsanaId
      dueDate
      status
      progress
      tenantId
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const clientRequestByTenant = /* GraphQL */ `
  query ClientRequestByTenant(
    $tenantId: String
    $clientName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientRequestByTenant(
      tenantId: $tenantId
      clientName: $clientName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaignID
        services
        clientName
        clientAsanaId
        campaignName
        campaignAsanaId
        dueDate
        status
        progress
        tenantId
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientRequests = /* GraphQL */ `
  query SyncClientRequests(
    $filter: ModelClientRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaignID
        services
        clientName
        clientAsanaId
        campaignName
        campaignAsanaId
        dueDate
        status
        progress
        tenantId
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      team
      name
      workspace
      asanaId
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      clientRequest {
        id
        campaignID
        services
        clientName
        clientAsanaId
        campaignName
        campaignAsanaId
        dueDate
        status
        progress
        tenantId
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      owner
      tasks {
        nextToken
        startedAt
      }
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        team
        name
        workspace
        asanaId
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const projectByTenant = /* GraphQL */ `
  query ProjectByTenant(
    $tenantId: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectByTenant(
      tenantId: $tenantId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        team
        name
        workspace
        asanaId
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProjects = /* GraphQL */ `
  query SyncProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        team
        name
        workspace
        asanaId
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      notes
      dueDate
      section
      workspace
      projectID
      asanaId
      tenantId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        notes
        dueDate
        section
        workspace
        projectID
        asanaId
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTasks = /* GraphQL */ `
  query SyncTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        notes
        dueDate
        section
        workspace
        projectID
        asanaId
        tenantId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
