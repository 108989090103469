export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';

export const SET_AUTH_INITIAL_STATE = 'SET_AUTH_INITIAL_STATE';

export const SET_ACTIVE_INDEX = 'SET_ACTIVE_INDEX';

export const SET_CLIENT_REQUEST = 'SET_CLIENT_REQUEST';

export const SET_PERMA_LINK_URL = 'SET_PERMA_LINK_URL';

