import React, {useContext, useEffect, useState} from 'react';
import {Message} from "primereact/message";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
//import {DataStore} from "@aws-amplify/datastore";
//import {Campaign, Service} from "../../models";
import {MultiSelect} from "primereact/multiselect";
import { Divider } from 'primereact/divider';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import AuthContext from "../../context/auth/authContext";
import axiosAsanaClient from "../../config/axios";
import "./ClientRequest.css"
import {API, Auth, graphqlOperation} from 'aws-amplify';
import gif from '../../static/rq_logo_animation_d5.gif';
import ClientRequestContext from "../../context/clientRequest/clientRequestContext";
//import {campaignByTenant, getCompany, listServices} from "../../graphql/queries";
import {campaignByTenantWithServices} from "../../graphql/customQueries";

const ClientRequestForm = () => {

    const authContext = useContext(AuthContext);
    const {user, company, logout} = authContext;

    const clientRequestContext = useContext(ClientRequestContext);
    const {clientRequest, setClientRequest, setActiveIndex} = clientRequestContext;


    const tenantId = user.attributes['custom:tenant'];

    const asanaUserGid = user.attributes['custom:asanaGid'];

    const [campaigns, setCampaigns] = useState([]);
    const [services, setServices] = useState([]);
    const [teams, setTeams] = useState([]);

    const [isValidForm, setIsValidForm] = useState(false);
    const [isFistTime, setIsFirsTime] = useState(true);

    useEffect(() => {
        if (!clientRequest.client || clientRequest.client.name.trim() === ''
            || !clientRequest.campaignName || clientRequest.campaignName.trim() === ''
            || !clientRequest.dueDate || !clientRequest.campaign || clientRequest.campaign.id === ''
            || !clientRequest.services || clientRequest.services.length <= 0) {
            setIsValidForm(false);
        } else {
            setIsValidForm(true);
        }

        if(isFistTime) {
            campaingByTenant();
            getTeams(true);
            setIsFirsTime(false);
        }
    }, [clientRequest]); // eslint-disable-line react-hooks/exhaustive-deps


    const campaingByTenant = async () => {
        try {
            /*const campaigns = await DataStore.query(Campaign,
                    c => c.tenantId("eq", tenantId)
                , {
                    sort: s => s.name(SortDirection.ASCENDING)
                }
            );*/

            const campaignByTenantWithServicesResult = await API.graphql(graphqlOperation(
                campaignByTenantWithServices, { tenantId: tenantId})
            );

            const campaigns = campaignByTenantWithServicesResult.data.campaignByTenant.items;

            setCampaigns(campaigns);

            /*setClientRequest({
                ...clientRequest,
                campaign: campaigns[0]
            });*/
            //await loadServices(campaigns[0].services.items);
        } catch (error) {
            console.log("Error retrieving campaigns", error);
        }
    }

    const getTeams = async (isFirstTime) => {
        try {
            console.log(company);
           // const response = await axiosAsanaClient.get(`organizations/${company.destinationWorkspace}/teams?`);
            
            /*if(response.data) {
                setTeams(response.data.data.filter( team =>  team.name !== 'Product' ));
            }*/

            const response = await axiosAsanaClient.get(`users/${asanaUserGid}/teams?organization=${company.destinationWorkspace}`);

            if(response.data) {
                setTeams(response.data.data);
            }

            if(!isFirstTime) {
                setClientRequest({
                    ...clientRequest,
                    client: null
                });
            }
            console.log("teams retrieved successfully!", JSON.stringify(response.data.data));
        } catch (error) {
            console.log("Error retrieving teams", error);
        }
    }


    const onHandleChange =e => {
        setClientRequest({
            ...clientRequest,
            [e.target.name]: e.target.value
        });
    }

    const onClientNameChange = async(e) => {
        setClientRequest({
            ...clientRequest,
            client: e.target.value
        });
    }

    const onCampaingChange = async(e) => {
        setClientRequest({
            ...clientRequest,
            campaign: e.target.value,
            services: []
        });

        if(!e.target || !e.target.value) {
            return;
        } else if (e.target.value && !e.target.value.id) {
            setServices([]);
            return;
        }

        await loadServices(e.target.value.services.items);
    }



    const loadServices = async (services) => {
        try {
            //another way to filter
            /*const services = (await DataStore.query(Service, Predicates.ALL, {
                sort: s => s.name(SortDirection.ASCENDING)
            })).filter(s => s.campaignID === e.target.value.id);*/

            /*const services = await  DataStore.query(Service,
                c => c.campaignID("eq", campaignId),{
                    sort: s => s.name(SortDirection.ASCENDING)
                });*/


           //const listServicesResult = await API.graphql(graphqlOperation(listServices, { filter: {campaignID: {eq: campaignId}}}));

            //const services = listServicesResult.data.listServices.items;


            setServices(services);
            console.log("Services retrieved successfully!", JSON.stringify(services));
        } catch (error) {
            console.log("Error retrieving services", error);
        }
    }

    const onCalculateBacktrackit =  async () => {
        try {
            setActiveIndex(1);            
        } catch (error) {
            console.log("Error saving ClientRequest", error);
        }
    }
    async function signOut() {
        logout();
    }

    return (
        <div className="datatable-crud-demo">
            {
                clientRequest !== null ? (
                        <div className="layout">
                                <div className="p-grid p-fluid container">
                                    <div className="p-grid p-fluid container1">
                                        <div  className="p-inline p-fluid logo">
                                            <div className="p-col-6 p-md-6">
                                                <img src={gif} alt="RED QUEEN" className="redQueen"/> 
                                            </div>
                                            <div className="logout-button-box">
                                                <Button className="logout-button" label="Log Out" onClick={signOut} />
                                            </div>
                                        </div>
                                    </div>

                                <Divider align="left" className="divider">
                                    <b>01 <i className="pi pi-minus"></i> CAMPAIGN CREATOR</b>
                                </Divider>

                                <span className="note-maps">Maps to Asana Team.</span>
                                <div className="p-inputgroup p-col-12 space">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <Dropdown id="client" name="client" placeholder="Client Name"  filter style={{width: '100%'}} className={clientRequest.client && clientRequest.client.name.trim() !== '' ? 'p-success':  'p-invalid'}
                                              onChange={onClientNameChange} value={clientRequest.client} options={teams}
                                              optionLabel="name" showClear appendTo={document.body}/>
                                              
                                              
                                </div>
                                {/*<div className="" style={{padding: '.5em'}}>
                                    <Button icon="pi pi-refresh" className="p-button-rounded p-button-success" onClick={ () => {getTeams(false)}}/>
                </div>*/}

                                {/*<div className="" style={{padding: '.5em'}}>
                                    { clientRequest.client && clientRequest.client.name.trim() !== '' ? null: <Message severity="error" text="Field is required" /> }
                </div>*/}

                                <span className="note-maps">Maps to Asana Project.</span>
                                <div className="p-inputgroup p-col-12 space">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-bookmark"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText id="campaignName" name="campaignName" rows={5} cols={30} className={clientRequest.campaignName.trim() === '' ? 'p-invalid': 'p-success'}
                                                   onChange={onHandleChange} value={clientRequest.campaignName} />
                                        <label htmlFor='campaignName'> Campaign Name </label>
                                    </span>
                                </div>
                                {/*<div className="" style={{padding: '.5em'}}>
                                    { clientRequest.campaignName.trim() === '' ? <Message severity="error" text="Field is required" />: null }
                </div>*/}


                                <div className="p-inputgroup p-col-12 space1">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-calendar"></i>
                                    </span>
                                     <span className="p-float-label">
                                         <Calendar id="dueDate" name="dueDate" value={clientRequest.dueDate} className={!clientRequest.dueDate ? 'p-invalid': 'p-success'}
                                                   onChange={onHandleChange}
                                                   showButtonBar monthNavigator yearNavigator yearRange="2020:2030"
                                                   dateFormat="mm/dd/yy"
                                                   appendTo={document.body}/>
                                        <label htmlFor='dueDate'> Due Date </label>
                                    </span>
                                </div>
                                {/*<div className="" style={{padding: '.5em'}}>
                                    { !clientRequest.dueDate ? <Message severity="error" text="Field is required" />: null }
                </div>*/}

                                <Divider align="left"className="divider" >
                                    <b>02 <i className="pi pi-minus"></i> SELECT A CAMPAIGN</b>
                                </Divider>


                                <div className="p-inputgroup p-col-12 space1">
                                    <Dropdown 
                                    id="campaignID" 
                                    name="campaignID" 
                                    placeholder="Select"  
                                    filter style={{width: '100%'}} 
                                    className={clientRequest.campaign && clientRequest.campaign.id !== '' ? 'p-success': 'p-invalid'}
                                    onChange={onCampaingChange} 
                                    value={clientRequest.campaign} 
                                    options={campaigns}
                                    optionLabel="name" 
                                    showClear='true'
                                    appendTo={document.body}/>
                                </div>
                                    {/*<div className="" style={{padding: '.5em'}}>
                                    { clientRequest.campaign && clientRequest.campaign.id !== '' ? null: <Message severity="error" text="Field is required" /> }
                                </div>*/}
                            
                            {
                                !clientRequest.campaign?
                                    null :  (
                                        <React.Fragment>

                                            <Divider align="left" className="divider" >
                                                <b>03 <i className="pi pi-minus"></i> SELECT THE SERVICES</b>
                                            </Divider>

                                            <div className="p-inputgroup p-col-12 space">
                                                <MultiSelect id="services" name="services" placeholder="Select" display="chip"  filter className={clientRequest.services === null ? 'p-invalid': 'p-success'}
                                                onChange={onHandleChange} value={clientRequest.services}  options={services} optionLabel="name" appendTo={document.body}/>
                                            </div>
                                            <div className="" style={{padding: '.5em'}}>
                                                { clientRequest.services === null ? <Message severity="error" text="Field is required" />: null }
                                            </div>
                                        </React.Fragment>
                                    )
                            }
                                {/*<div className="" style={{padding: '.5em'}}>
                                    { clientRequest.services === null ? <Message severity="error" text="Field is required" />: null }
            </div>      */}
                                <div className="" style={{paddingTop: '2em'}}>
                                    <Button label="Backtrack It!"  className="p-button-rounded login-button" onClick={onCalculateBacktrackit} disabled={!isValidForm}/>
                                </div>

                            </div>
                        </div>
                    ) : null
            }
        </div>
    ) ;
}

export default ClientRequestForm;
