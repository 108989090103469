import React, { useContext } from 'react';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import gif from '../../static/rq_logo_animation_d5.gif'
import { Divider } from 'primereact/divider';
import ClientRequestContext from "../../context/clientRequest/clientRequestContext";
import AuthContext from "../../context/auth/authContext";
import './ClientRequest.css';
import {ClientRequest} from "../../models";



const ClientRequestExport = () => {

    const authContext = useContext(AuthContext);
    const {logout} = authContext;


    const clientRequestContext = useContext(ClientRequestContext);
    const {permalinkUrl, setActiveIndex, setClientRequest} = clientRequestContext;

    const onClickReturn =  async () => {
        try {
            setActiveIndex(0);
            setClientRequest(new ClientRequest({campaignName: ''}),);
        } catch (error) {
            console.log("Error saving ClientRequest", error);
        }
    }

    async function signOut() {
        logout();
    }

  return (
        <div className="loginForm">
            <div className="container">
                <div className="p-grid p-fluid container1">
                    <div  className="p-inline p-fluid logo">
                        <div className="p-col-6 p-md-6">
                            <img src={gif} alt="RED QUEEN" className="redQueen"/> 
                        </div>
                        <div className="logout-button-box">
                            <Button className="logout-button" label="Log Out" onClick={signOut} />
                        </div>
                    </div>
                </div>
            <div>
                <Divider align="left" className="divider">
                    <b>05 <i className="pi pi-minus"></i> EXPORT CONFIRMATION</b>
                </Divider>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-12">
                    <p>We have successfully exported the data into Asana.</p>
                    <p>Click the link to view the project:</p>
                    <a className="link" target="_blank" href={permalinkUrl}>{permalinkUrl}</a>
                    </div>
                </div>
                <Button className="export-button" label="New Campaign" onClick={onClickReturn} />
            </div>
            </div>
        </div>

  );
  
}

export default ClientRequestExport
