export const campaignByTenantWithServices = /* GraphQL */ `
  query CampaignByTenant(
    $tenantId: String
  ) {
    campaignByTenant(
      tenantId: $tenantId
    ) {
      items {
        id
        name
        services {
            items {
              id
              name
              description
            }
        }
      }
      nextToken
      startedAt
    }
  }
`;
