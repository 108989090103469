import {SET_ACTIVE_INDEX, SET_CLIENT_REQUEST, SET_PERMA_LINK_URL} from "../../types";

export default (state, action) => {
    switch (action.type) {
        case SET_ACTIVE_INDEX:
            return {
                ...state,
                activeIndex: action.payload
            }
            break;
        case SET_CLIENT_REQUEST:
            return {
                ...state,
                clientRequest: action.payload
            }
            break;
        case SET_PERMA_LINK_URL:
            return {
                ...state,
                permalinkUrl: action.payload
            }
            break;
        default:
            return state;
    }
}
