import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import ForgotPassword from './ForgotPassword'
import Login from './Login'
import gif from '../../static/rq_logo_animation_d5.gif'

import '../../App.css';

export default function Sended () {
    const [back, setBack] = useState(false);
    const [sended, setSended] = useState(false);
    const [userExist, setUserExist] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [messageError, setMessageError] = useState('');
    
    const [data, setData] = useState({
        email: '',
        code: '',
        new_password: '',
    })

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name] : event.target.value
        })
    }

    let handleSubmit = async function (e) {
        e.preventDefault();

        let authData = await Auth.forgotPasswordSubmit(data.email, data.code, data.new_password)
        .then(data => {
            setSended(true);
        })
        .catch(err => {
            console.log(err.message)
            setMessageError(err.message);
            setUserExist(true);
            setTimeout(function () {
                setUserExist(false)
            }, 2500)
        });

        return authData
    }

    const handleBack = (e) => {
        setBack(true);
    }

    let togglePasswordVisibility = () => {
        setPasswordShow(!passwordShow );
    }


    if(back) {
        return <ForgotPassword />    
    }else if (sended){
        return <Login />
   }
    return (
        <form className="loginForm" onSubmit={handleSubmit}>
            <div className="container">
                <div className="p-col-12 p-md-12">
                    <img src={gif} alt="RED QUEEN" className="redQueen"/> 
                </div>
                <div className="card">
                    <h4 className="text1">RESET YOUR PASSWORD</h4>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-12">
                            <div className="p-inputgroup form2">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-check"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText id="inputgroup2" type="text" value={data.code} name="code" onChange={handleChange}/>
                                    <label htmlFor="inputgroup">Code</label>
                                </span>
                            </div>
                            <div className="p-inputgroup form2">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-envelope"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText id="inputgroup3" type="text" value={data.email} name="email" onChange={handleChange}/>
                                    <label htmlFor="inputgroup">Email</label>
                                </span>
                            </div>
                            <div className="p-inputgroup form2">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-lock"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText id="inputgroup4" type={passwordShow ? "text" : "password"} value={data.new_password} name="new_password" onChange={handleChange}/>
                                    <label htmlFor="inputgroup">New Password</label>
                                </span>
                                <span className="p-inputgroup-addon eye">
                                    <i className={passwordShow ? "pi pi-eye" : "pi pi-eye-slash"} onClick={togglePasswordVisibility}></i>
                                </span>
                            </div>
                            { 
                                userExist ? (
                                    <span className="error">{messageError}</span>
                                ):(
                                    <div></div>
                                )
                            }
                        </div>
                    </div>
                    <Button className="login-button" label="Change" type="submit"/>
                    <Button className="login-button" label="Back" onClick={handleBack}/>
                </div>
            </div>
        </form>
    )
}


