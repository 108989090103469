import React, {useContext} from 'react';
import './ClientRequest.css';
import ClientRequestForm from "./ClientRequestForm";
import ClientRequestConfirmation from "./ClientRequestConfirmation";
import ClientRequestExport from "./ClientRequestExport";
import ClientRequestContext from "../../context/clientRequest/clientRequestContext";
import ForgotPassword from "../Auth/ForgotPassword"

const ClientRequestWizard = ( ) => {

    const clientRequestContext = useContext(ClientRequestContext);
    const {activeIndex} = clientRequestContext;

    return (
        <div className="client-request">
            {
                activeIndex === 0 ?
                    <ClientRequestForm/>
                : (
                    activeIndex === 1 ?
                        <ClientRequestConfirmation/>
                    :(
                        activeIndex === 2 ?
                           <ClientRequestExport/>
                        :(
                            activeIndex === 3 ?
                               <ForgotPassword/>
                            : null
                        )
                    )
                )

            }
        </div>
    );
}

export default ClientRequestWizard;
