import React, {useReducer} from "react";
import ClientRequestContext from "../clientRequest/clientRequestContext";
import {ClientRequest} from "../../models";
import ClientRequestReducer from './clientRequestReducer';
import {SET_ACTIVE_INDEX, SET_CLIENT_REQUEST, SET_PERMA_LINK_URL} from "../../types";

const ClientRequestState = props => {
    const initialState = {
        activeIndex: 0,
        clientRequest: new ClientRequest({campaignName: ''}),
        permalinkUrl: ''
    }

    const [state, dispatch]  = useReducer(ClientRequestReducer, initialState);

    const setActiveIndex = index => {
        dispatch ({
            type: SET_ACTIVE_INDEX,
            payload: index
        });
    }

    const setClientRequest = clientRequest => {
        dispatch ({
            type: SET_CLIENT_REQUEST,
            payload: clientRequest
        });
    }

    const setPermalinkUrl = permalinkUrl => {
        dispatch ({
            type: SET_PERMA_LINK_URL,
            payload: permalinkUrl
        });
    }

    return (
        <ClientRequestContext.Provider
            value={{
                activeIndex: state.activeIndex,
                clientRequest: state.clientRequest,
                permalinkUrl: state.permalinkUrl,
                setActiveIndex,
                setClientRequest,
                setPermalinkUrl
            }}
        >
            {props.children}
        </ClientRequestContext.Provider>
    )
}

export default ClientRequestState;
