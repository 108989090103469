import React from 'react';
import './App.css';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import AuthState from './context/auth/authState';
import Auth1 from "./components/Auth/Auth";
import asanaTokenAuth from "./config/asanaToken";

const company = JSON.parse(sessionStorage.getItem('company'));

if(company) {
    asanaTokenAuth(company.destinationPersonalToken);
}

function App() {
    return (
        <AuthState>
           <Auth1/>
        </AuthState>
    );
}
export default App;
