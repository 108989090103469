// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const StatusEnum = {
  "PLANNED": "PLANNED",
  "IN_PROGRESS": "IN_PROGRESS",
  "COMPLETED": "COMPLETED"
};

const { Company, Campaign, Service, TaskTemplate, ClientRequest, Project, Task } = initSchema(schema);

export {
  Company,
  Campaign,
  Service,
  TaskTemplate,
  ClientRequest,
  Project,
  Task,
  StatusEnum
};